//one load
if(appInit == undefined || appInit == null){

    var LGEAPPHostName = window.location.hostname;
    var LGEcomfirmAPPInstall, LGEquickMenuPosCover, LGEquickMenuPosPush, LGEAPPcomfirmAPPOpen, LGEAPPalarmCount, receiptCodeDirectReturn, receiptCodeDirectInput;
    var LGEAPPclickCNT = 0;
    
    /*
    IOS:        /ipod|iphone|ipad/.test(navigator.userAgent.toLowerCase()),
    IPHONE:     /iphone/.test(navigator.userAgent.toLowerCase()),
    IPAD:       /ipad/.test(navigator.userAgent.toLowerCase()),
    ANDROID:    /android/.test(navigator.userAgent.toLowerCase()),
    WINDOWS:    /windows/.test(navigator.userAgent.toLowerCase()),
    MOBILE:     /mobile/.test(ua)
    */
    //BTOCSITE-43868 마이페이지 > 앱설정 예외처리
    var isMyPage = false;

    var appInit = function() {
        var agent = navigator.userAgent;
        //console.log('앱 스크립트 시작');
        if (LGEAPPHostName != "cmsdev50.lge.co.kr" && LGEAPPHostName != "cms50.lge.co.kr") {
            if (isApp() && window.innerWidth < 768) {
                //console.log('앱 확인');
                //헤더 앱 설정 버튼
                $('.mapExclusive').addClass('active');
                $('.mapExclusiveDss').hide();
                $('.mypage-main-new .app-only').css('display','inline-block'); // LGECOMVIO-30 : 마이페이지 홈 1차 개선 - 앱전용 옵션(20220520)
                // $('.app-alarm-button').css('right','30px'); // 210621 앱 알림함 아이콘 추가 원복처리 
                $(".app-settings-button").off('click').on('click',function(e){
                    //e.preventDefault()
                    //document.location.href="/mobile-app/option";
                    //window.open("https://wwwdev50.lge.co.kr/mobile-app/option", '_blank');
    
                    //BTOCSITE-21934
                    openAppSet();
                    ////BTOCSITE-21934
                });

                //BTOCSITE-43868 마이페이지 > 앱설정 예외처리
                if($(".my-dep2.app-only").length > 0){
                    $(".my-dep2.app-only").attr('href','#').off('click').on('click',function(e){
                        e.preventDefault();
                        openAppSet_direct();
                    });
                }

                //Quick메뉴, Easy-path 삭제
                $('.subRenewMainWrap .KRP0032,.subRenewMainWrap .quick-menu-list,.subRenewMainWrap .easy-path').remove();

                //앱 개인정보 수정 링크 변경
                $(".mod-link").each(function(){ 
                   this.href = this.href.replace("changeInfo.do", "changeInfo_app.do");
                });
    
    
                if ($(".subRenewMainWrap .btn-app-ar").length > 0){
                    return;
                }
    
                /*if ($(".subRenewMainWrap").length > 0) {
                    //console.log('AR 버튼 추가');
                    //Quick메뉴 AR 버튼 추가
                    $(".subRenewMainWrap .floating-wrap").prepend('<div class="floating-menu cs-cst btn-app-ar"><div class="app-ar"><button href="javascript:void(0);"><span>AR</span><span class="app-ar-txt"><i></i>제품을 가상으로 배치해보세요</span></button></div></div>');
                    $(".subRenewMainWrap .btn-app-ar").show();
                    var $arBtn = $(".subRenewMainWrap .btn-app-ar button");
    
                    function rafRun(cb) {
                        var tick = false
    
                        return function trigger() {
                            if (tick) {
                                return
                            }
    
                            tick = true
                            return requestAnimationFrame(function task() {
                                tick = false
                                return cb()
                            })
                        }
                    }
    
                    function setArBtn() {
                        // 플로팅 버튼 AR 관련
                        if (vcui.detect.isMobileDevice) {
                            rafRun(function () {
                                $('.floating-menu.btn-app-ar').css('display', 'block');
                                $('.floating-menu.top').hide();
                                $('.floating-menu.top').addClass('call-yet');
                                $(window).trigger('floatingTopHide');
                            });
                        }
                    }
    
                    setArBtn();
    
                    //Quick메뉴 AR 버튼 이벤트
                    $arBtn.off("click focusin focusout").on({
                        click : function() {
                            $(this).addClass("active");
    
                            if(LGEAPPclickCNT > 0){
                                if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
                                    var obj = new Object();
                                    obj.command = "showAR";
                                    var jsonString= JSON.stringify(obj);
                                    webkit.messageHandlers.callbackHandler.postMessage(jsonString);
                                } else {
                                    void android.openAR(null);
                                }
                            }
                            LGEAPPclickCNT++;
                        },
                        focusin : function(){
                            setTimeout(function(){
                                $arBtn.addClass("active");
                                LGEAPPclickCNT = 1;
                            }, 150);
                        },
                        focusout : function(){
                            $arBtn.removeClass("active");
                            LGEAPPclickCNT = 0;
                        }
                    });
    
                    //스크롤 시 AR 버튼 default 상태로 변경
                    $(window).on('scroll.floating', function () {
                        var scrollTop = $(window).scrollTop();
                        var hasTop = $('.floating-menu.top').hasClass('call-yet');
    
                        if (scrollTop == 0) {
                            if (hasTop) {
                                $('.floating-menu.btn-app-ar').css('display', 'block');
                                $(window).trigger('floatingTopHide');
                            } else {
                                //앱인데 메인이 아닐경우에만 실행
                                $('.floating-menu.btn-app-ar').css('display', 'block');
                                $(window).trigger('floatingTopHide');
                                //임시 추가 끝
                            }
                        } else {
                            if (scrollTop > 100 && $arBtn.hasClass('active')) {
                                $arBtn.trigger('focusout');
                            } else {
                                if (hasTop) {
                                    $('.floating-menu.btn-app-ar').css('display', 'block');
                                    $(window).trigger('floatingTopShow');
    
                                } else {
                                    $('.floating-menu.btn-app-ar').css('display', 'block');
                                    $(window).trigger('floatingTopShow');
                                }
                            }
                        }
                    });
                }*/
    
    
                if($(".subRenewMainWrap").length > 0 || $(".signature-main").length > 0 || $(".thinq-main").length > 0) {
                    if(agent.indexOf("LGEAPP-in") != -1) {
                        //노치 있음
                        $(".subRenewMainWrap .floating-wrap").addClass("app-LGEAPP-in");
                    }else if(agent.indexOf("LGEAPP-io") != -1) {
                        //노치 없음
                        $(".subRenewMainWrap .floating-wrap").addClass("app-LGEAPP-io");
                    }else if(agent.indexOf("LGEAPP") != -1) {
                        //안드로이드
                        $(".subRenewMainWrap .floating-wrap").addClass("app-LGEAPP");
                    }
    
                    /*
                    덮는 경우 : app-chng-pos
                    미는 경우 : app-chng-push-pos
                    */
                    //하단 탭바 덮는 경우
                    LGEquickMenuPosCover = function(bool){
                        if(bool == "Y"){
                            $('.subRenewMainWrap .floating-wrap').removeClass('app-chng-push-pos').addClass('app-chng-pos');
                        }else{
                            $('.subRenewMainWrap .floating-wrap').removeClass('app-chng-push-pos').removeClass('app-chng-pos');
                        }
                    }
                    //하단 탭바 미는 경우
                    LGEquickMenuPosPush = function(bool){
                        $('.subRenewMainWrap .floating-wrap').removeClass('app-chng-pos').addClass('app-chng-push-pos');
                    }
                }            
    
                //알림함 Count 표시
                /* 210621 알람아이콘 롤백처리 -> 추후 오픈 예정 */
                if (/iPhone|iPad|iPod/i.test(agent)) {
                    var obj = new Object();
                    obj.command = "getUncheckedPushCount";
                    obj.callback ="LGEAPPalarmCount";
                    var jsonString= JSON.stringify(obj);
                    webkit.messageHandlers.callbackHandler.postMessage(jsonString);
                }else if(/Android/i.test(agent) && android.hasOwnProperty('getUncheckedPushCount')) {
                    android.getUncheckedPushCount("LGEAPPalarmCount");
                }else{
                    //console.log("Count Update");
                }

                //알림함 버튼 이벤트  
                $(".app-alarm-button").on({
                    click : function(){
                        if (/iPhone|iPad|iPod/i.test(agent)) {
                            var obj = new Object();
                            obj.command = "openPushBox";
                            var jsonString= JSON.stringify(obj);
                            webkit.messageHandlers.callbackHandler.postMessage(jsonString);
                        }else if(/Android/i.test(agent)) {
                            android.openPushBox();
                        }else{
                            //console.log("not iPhone/Android");
                        }
                    }
                });

                // BTOCSITE-27076 AR 배너 클릭
                $(".section--ar").find("a").on('click',function(e){
                    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
                        var obj = new Object();
                        obj.command = "showAR";
                        var jsonString= JSON.stringify(obj);
                        webkit.messageHandlers.callbackHandler.postMessage(jsonString);
                    } else {
                        void android.openAR(null);
                    }
                });

                /*
                //옵션창 열기
                var isLoadAppJs = false;
                $(".app-settings-button, .my-dep2.mod-link.app-only")
                    .off("click")
                    .on({
                        click: function (e) {
                            e.preventDefault();
                            lgkorUI.showAppBottomMenu(false);
                            lgkorUI.setEnableAppScrollBottomMenu(false);
                            if (!isLoadAppJs) {
                                var script = document.createElement("script");
                                script.src = "/lg5-common/js/app.option.min.js";
                                script.type = "text/javascript";
                                document.head.appendChild(script);
                                isLoadAppJs = true;
                            }
                            //BTOCSITE-21934 로그인 분기 처리 start
                            if (!$('.header-wrap .mobile-nav-button').hasClass('active')) {
                                lgkorUI.addHistoryBack('option1', function(){
                                    $(".btn-opt-close").trigger("click");
                                });
                            }                        
     
                            var loginCheckUrl = $(document).find("header").data("loginInfo") || "/mkt/ajax/commonmodule/loginInfo";
                            $.ajax({
                                type: "post",
                                url: loginCheckUrl,
                                dataType: "json",
                                success: function (data) {
                                    if (data.data.isLogin) {
                                        var target = $('.lyr-opt-panel .opt-controls').first();
                                        target.find('.name').text(data.data.name);
                                        target.show();
                                    }
                                    
                                    $(".lyr-opt-panel").stop().animate({ left: "0" }, 250);
                                    $(".main-panel")
                                        .stop()
                                        .animate({ left: "0" }, 250, function () {
                                            //설정 화면 [설정] 타이틀 포커스
                                            $(this).find("h1").attr("tabindex", 0).focus();
                                        });  
                                },
                            });
                            //BTOCSITE-21934 로그인 분기 처리 end                      
                        },
                    });
    
    
                //옵션창 닫기
                $(".btn-opt-close")
                    .off("click")
                    .on({
                        click: function () {
                            lgkorUI.showAppBottomMenu(true);
                            lgkorUI.setEnableAppScrollBottomMenu(true);
                            if (!$('.header-wrap .mobile-nav-button').hasClass('active')) {
                                lgkorUI.removeHistoryBack('option1');
                            }
                            $(".lyr-opt-panel, .main-panel")
                                .stop()
                                .attr("aria-hidden", true)
                                .animate({ left: "100%" }, 250, function () {
                                    //접근성
                                    $(".main-panel").attr("aria-hidden", true).removeAttr("tabindex");
                                    $(".app-settings-button").focus();
                                });
                            return false;
                        },
                    });
                    */
    
            }
    
            // s : BTOCSITE-12307
            receiptCodeDirectReturn = function(barcode) {
                if (barcode != null && barcode != "" && barcode != undefined) {
                    var setBarcode = barcode.split('/').join('');
                    if(chkSerialNum(setBarcode)) {
                        var getBarcode = ($('#categorySelect').val() == 'EMRT') ? setBarcode.slice(0,16):setBarcode.slice(0,22);
                        $('#receiptCodeInputWrap').find('.err-block').hide().find('.err-msg').text("")
                        $("#inputReceipt").val(getBarcode);
                        $('#receiptCodeInputWrap').show();
                        $('#inquiryButton').focus();
                    } else {
                        $('#receiptCodeInputWrap').find('.err-block').show().find('.err-msg').text("바코드 형식이 잘못되었습니다.")
                        $('#receiptCodeInputWrap').show();
                    }
                }
            }
    
            receiptCodeDirectInput = function() {
                $('#receiptCodeInputWrap').show();
                $('#inputReceipt').val('').focus();
            }
            // e : BTOCSITE-12307
    
            function chkSerialNum(barcode) {
    
                var minLengthFlag = barcode.length >= 10 ? true: false;
                    
                var currentVal = [];
                var checkSerialSuccess = [];
                var regexArry = [/^[0-9]+$/, /^[a-zA-Z0-9]+$/, /^[0-9]+$/];
    
                currentVal[0] = barcode.slice(0,3);
                currentVal[1] = barcode.slice(3,14);
                currentVal[2] = barcode.slice(14,18);
    
                currentVal.forEach(function(v, i){
                    if( v != "" ) {
                        checkSerialSuccess.push(regexArry[i].test(v))
                    }
                })
    
                return (minLengthFlag && checkSerialSuccess.indexOf(false) == -1) ? true : false;
            }
            //BTOCSITE-4086 210928 - E
            
            //iOS, Android 앱 설치 여부 확인
            LGEcomfirmAPPInstall = function() {
                goAppUrl();   // BTOCSITE-5938-237 앱실행 방법 변경
            }
    
            //알림함 Count 표시
            // 210701 알람체크 N뱃지 아이콘
            LGEAPPalarmCount = function(cnt){
                // 숫자 아닌경우 타입 예외 처리 2021-07-14
                var count = cnt || 0;
    
                if(typeof cnt !== 'number') {
                    count = /^\d+$/.test(cnt) ? Number(cnt) : 0;
                }
                
                var $target = $(".app-alarm-button .app-alarm-count");
    
                if($target.length > 0){
                    $target.removeClass("active");
                    
                    if(count > 0){
                        $target.addClass("active");
                    }
    
                    $target.html(count > 99 ? '99+' : count);
                }
                /* 
                [BTOCSITE-69338] APP 알림 미확인시 모바일 상단 햄버거 메뉴N 표기 삭제요청
                // 210701 알람체크 N뱃지 아이콘
                var $mobNavBtn = $(".mobile-nav-button");
                if(count > 0 && !$mobNavBtn.find('.count').length) $mobNavBtn.append("<span class='count'><span class='blind'>알림메시지 카운트 존재시</span>N<span>");
                else  $mobNavBtn.find('.count').remove();
                */
            }
        }
    };
    
    
    function ChatbotAppClose(type) {
        // 앱에서 호출될경우
        if(isApp()) {
            if(vcui.detect.isIOS){ 
               var jsonString = JSON.stringify({'command':'closeInAppBrowser'});
               webkit.messageHandlers.callbackHandler.postMessage(jsonString);
            }else{
                android.closeNewWebview(); 
            }
        } else {
            if(type == 'native') {
                history.back();
            } else {
                //웹에서 호출될경우
                historyBack();
            }
        }
    }
    // 스와이프 적용일때 분기 처리
    $(document).ready(function(){
        var isSwipe = !!$('#sw_con').length;
        if ( isSwipe && isApp() ){
            $(document).one('appInit', appInit);
        } else {
            appInit();
        }
        initAppSet();

        callBackLoginCheck();
        setIOSToTalMenuBottomBar();
    });

    //BTOCSITE-21934
    //앱설정 페이지에서 호출 
    function getAppOption(){
        callNativeIos('getDevicePushState','ACTIVITY',null,'setItemIosPushAlarm');
        callNativeIos('getSettingOptions','ACTIVITY',null,'setItemIosValue');
        callNativeIos('isUseSettingGps','ACTIVITY',null,'setIsUseSettingGps');
    }
    function callNativeIos(command,key,value,callback){
        var obj = new Object();
        obj.command = command;
        if(key != null) obj.key = key;
        if(callback != null) obj.callback = callback;
        if(value != null) obj.value = value;
        var jsonString= JSON.stringify(obj);
        //$('.foot-last').append('<div>callNativeIosTest jsonString:'+jsonString+'</div>')
        webkit.messageHandlers.callbackHandler.postMessage(jsonString);
    }
    function setItemIosPushAlarm(_value){
        localStorage.setItem('setIosPushAlarm', _value);
    }
    function setItemIosValue(_value){
        localStorage.setItem('setIosValue', _value);
    }
    // iPhone에서 LGE.COM APP의 위치정보 이용 허용 여부 저장
    function setIsUseSettingGps(_value) {
        // alert("setIsUseSettingGps : " + _value);
        localStorage.setItem('isUseSettingGps', _value);
    }
    ////앱설정 페이지에서 호출

    // 폰에서 설정페이지 이동후 APP으로 되돌아오면 호출 되는 함수.
    // 이것을 이용하여 폰 설정에서 돌아올 때 처리(위치 정보 수정)
    function phoneSettingReturn() {
        // alert("Call Back Function : phoneSettingReturn");
        $("#viewDotJspCall")[0].contentWindow.phoneSettingReturnChild();     // iframe 자식 페이지의 함수 호출
    }

    // Url에 특정 파라메터의 값이 있으면 앱 설정 페이지를 연다.
    // 앱설정에서 로그인 하면 로그인 후에 앱 설정을 열어주기 위함.
    // 앱설정에서 로그인 페이지 이동시에만 해당 파라메터 세팅
    function callBackLoginCheck() {
        var isApplication = isApp();
        if (isApplication) {
            if (window.location.search != undefined && window.location.search != null && window.location.search != "") {     // 파라메터 확인
                var thisPageUrl   = window.location.href;

                if (thisPageUrl.indexOf("cbf=appset") > -1) {     // cbf의 파라메터가 appset이면 앱 설정 열기
                    historyBackTotalMenuOpen();                 // 사이드 메뉴 노출
                    $('.subRenewWrap').addClass('isHide');      // 앱설정시 헤더와 겹치고 메뉴 닫기시 헤더가 사라지는 현상 방지
                    openAppSet();                               // 앱설정 노출
                    history.replaceState(null, "LG 전자", appSettingLoginReturnUrlCheck());   // 현재 url 재설정
                }
            }
        }
    }

    // Url에서 cbf=appset 파라메터 제거
    // 새로 고침이나 해당 페이지 재 로딩시 웹설정을 띄우지 않기 위함
    function appSettingLoginReturnUrlCheck() {
        var searchParams    = window.location.href;
        var retParams       = "";

        if (searchParams.indexOf("&cbf=appset") > -1) {
            retParams = searchParams.replace("&cbf=appset", "");
        } else if (searchParams.indexOf("?cbf=appset") > -1) {
            retParams = searchParams.replace("?cbf=appset", "");
        }

        return retParams;
    }

    // iframe 안의 view.jsp에서 로그인 필요시 여기를 호출하여 로그인 호출 (cbf - call back flag : 앱설정에서 호출 되었다는 것)
    // 앱 설정은 어느 페이지에서나 호출 될 수 있어 경로와 파라메터를 추가해 주어야 하며 앱에서 호출 되었다는 파라메터 추가
    function appSettingLoginCall(loginUrl) {

        if(window.location.search != undefined && window.location.search != null && window.location.search != ""){
            location.href = loginUrl + "?state=" + encodeURIComponent(window.location.pathname + window.location.search + '&cbf=appset');
        } else {
            location.href = loginUrl + "?state=" + encodeURIComponent(window.location.pathname + '?cbf=appset');
        }

    }
    //B2B 인식
    var isB2B = window.isB2B;
    window.addEventListener('pageshow', function(e){
        if ( e.persisted || (window.performance && window.performance.navigation.type == 2)) {
            // Back Forward Cache로 브라우저가 로딩될 경우 혹은 브라우저 뒤로가기 했을 경우
            if($('.mobile-nav-button').hasClass('active')){
                //B2B에서 전체 메뉴 열렸을때 하단바 비활성화
                openB2BAppBar();
            }else{
                //B2B 하단바 기본 활성화 설정
                $(window).trigger('DOMContentLoaded_setAppBottomBar');
            }
        }
    });
    //앱설정 페이지 열기
    window.addEventListener('popstate', function(e){
        //뒤로가기나, lgkorUI.removeHistoryBack 호출시 popstate 이벤트 호출됨

        //전체메뉴 오픈
        //.history-back-header_1-open
        //.history-back-header_1
        if(isApp() && e.state ){
            var stateData = e.state.data;
            //console.log('e.state.data :: '+stateData);
            if(stateData == '.history-back-header_1' || stateData == '.history-back-header_2' || stateData == '.history-back-header_3' || stateData == '.history-back-header_4'){
                //전체 메뉴 닫힐때
                setTimeout(function(){
                    if(vcui.detect.isAndroid && $('body').hasClass('scroll-lock') == false ){
                        //console.log('전체메뉴닫기 closeMenu popstate');
                        android.closeMenu();
                        closeB2BAppBar();
                    }
                    if(vcui.detect.isIOS && $('body').hasClass('scroll-lock') == false){
                        var jsonString= JSON.stringify({command:'closeMenu'});
                        webkit.messageHandlers.callbackHandler.postMessage(jsonString);
                    }
                },1000);
            }else if(stateData == '.history-back-header_1-open' || stateData == '.history-back-header_2-open' || stateData == '.history-back-header_3-open' || stateData == '.history-back-header_4-open'){
                //앱설정 닫힐때
                $('#appSettings').hide();
                lgkorUI.setEnableAppScrollBottomMenu(true);
                lgkorUI.showAppBottomMenu(true);
            }

            //BTOCSITE-43868 마이페이지 > 앱설정 예외처리
            if(stateData == 'myPage' ){
                closeAppSet_direct();
            }
        }
    });
    function initAppSet(){
        var isApplication = isApp();
        var isAndroid = vcui.detect.isAndroid;
        var isIOS = vcui.detect.isIOS;
        var $btnCloseMy = $('.mobile-nav-button');
        var $bottomBarlist = $('.mobile-status-list');
        var $bottomBarMenu = $('.mobile-status-list .list-item');
        $btnCloseMy.on('click',function(e){
            // BTOCSITE-77494
            // lgkorUI.showAppBottomMenu(false);
            // $('.login-mobile .after-login>a').focus()
            setTimeout(function(){
                if($btnCloseMy.hasClass('active')){
                    //console.log('전체메뉴 열기');
                    // BTOCSITE-77494
                    $(window).trigger('mo_total_menu_acc')
                    openB2BAppBar();
                }else{
                    //console.log('전체메뉴 닫기');
                    // BTOCSITE-77494
                    $(window).trigger('mo_total_menu_acc')
                }
            });
        });
    }
    //BTOCSITE-77496 [웹앱 접근성 개선활동] 전시_APP
    function focusAppSet(){
        var loginFlag = digitalData.hasOwnProperty("userInfo") && digitalData.userInfo.unifyId ? "Y" : "N";
        if(loginFlag == 'Y'){
            $("#viewDotJspCall").contents().find(".btn-opt-login").focus();
        }else{
            $("#viewDotJspCall").contents().find('.btn-link.btn-ctrl-panel').focus();
        }
    }

    function appSetIframeload(_loaded){
        //BTOCSITE-77496 [웹앱 접근성 개선활동] 전시_APP
        if(_loaded == 'loaded'){
            setTimeout(function(){
                focusAppSet()
            },400);
        }else{
            focusAppSet();
        }

        //$('.header-wrap .mobile-nav-button').trigger('click');
        setTimeout(function(){
            lgkorUI.hideLoading();
            if(vcui.detect.isAndroid){
                historyBackTotalMenuClose();
                lgkorUI.addHistoryBack('openTotalmenu',function(){
                    closeAppSet();
                })
                
            }else if(vcui.detect.isIOS){
                history.pushState({ data: 'openMeun' },null,null);
            }
            // BTOCSITE-77494
            $(window).trigger('open_app_set')
        },300);
    }
    function logoutAppSet(){
        location.href = "/mobile-app/option/Logout";
    }
    function closeAppSet(){
        $('.subRenewWrap').attr('aria-hidden',false); //BTOCSITE-77496 [웹앱 접근성 개선활동] 전시_APP
        if(isMyPage){
            closeAppSet_direct();
            return;
        }
        $('#appSettings').hide();
        lgkorUI.setEnableAppScrollBottomMenu(true);
        lgkorUI.showAppBottomMenu(true);
        if(vcui.detect.isAndroid){
            lgkorUI.removeHistoryBack('openTotalmenu');
            setTimeout(function(){
                historyBackTotalMenuOpen();
                //$('.header-wrap .mobile-nav-button').trigger('click');
            },100);
        }else if(vcui.detect.isIOS){
            history.back();
        }
    }
    
    function openAppSet(){
        $('.subRenewWrap').attr('aria-hidden',true); //BTOCSITE-77496 [웹앱 접근성 개선활동] 전시_APP

        lgkorUI.setEnableAppScrollBottomMenu(false);
        lgkorUI.showAppBottomMenu(false);
        if($('#appSettings').length == 0){
            lgkorUI.showLoading(); 
            var iframe = '<div id="appSettings" style="z-index:99998;position:fixed;top:0;left:0;width:100%;height:100%;">';
            iframe +=       '<iframe id="viewDotJspCall" onload="appSetIframeload('+"'"+'loaded'+"'"+')" src="/mobile-app/option" frameborder="0" width="100%" height="100%" marginwidth="0" marginheight="0"></iframe>'; //BTOCSITE-77496 [웹앱 접근성 개선활동] 전시_APP
            iframe += '</div>';
            $('body').append(iframe);
        }else{
            setTimeout(function(){
                appSetIframeload();
            },300)
        }
        $('#appSettings').show();
    }
    ////앱설정 페이지 열기
    ////BTOCSITE-21934
    
    function historyBackTotalMenuClose(){
        $('html').removeClass('scroll-fixed');
        $('body').removeClass('scroll-lock');
        $('.mobile-nav-button').removeClass('active').removeClass('done');
        $('.header-wrap .dimmed').hide();
        $('.list-item.nav-anchor').removeClass('on');
        $('.list-item.home').addClass('on')
    }
    function historyBackTotalMenuOpen(){
        $('html').addClass('scroll-fixed');
        $('body').addClass('scroll-lock');
        $('.mobile-nav-button').addClass('active').addClass('done');
        $('.header-wrap .dimmed').show();
        $('.list-item.nav-anchor').addClass('on');
        $('.list-item.home').removeClass('on')
    }

    //BTOCSITE-43868 마이페이지 > 앱설정 예외처리
    function openAppSet_direct(){
        isMyPage = true;
        if($('#appSettings').length == 0){
            lgkorUI.showLoading(); 
            var iframe = '<div id="appSettings" style="z-index:99998;position:fixed;top:0;left:0;width:100%;height:100%;">';
            iframe +=       '<iframe onload="appSetIframeload_direct()" src="/mobile-app/option" frameborder="0" width="100%" height="100%" marginwidth="0" marginheight="0"></iframe>';
            iframe += '</div>';
            $('body').append(iframe);
        }else{
            setTimeout(function(){
                appSetIframeload_direct();
            },300)
        }
        $('#appSettings').show();
    }
    function appSetIframeload_direct(){
        lgkorUI.setEnableAppScrollBottomMenu(false);
        lgkorUI.showAppBottomMenu(false);
        setTimeout(function(){
            lgkorUI.hideLoading();
            if(vcui.detect.isAndroid){
                $('html').addClass('scroll-fixed');
                $('body').addClass('scroll-lock');
                lgkorUI.addHistoryBack('openTotalmenu',function(){
                    closeAppSet_direct();
                })
                
            }else if(vcui.detect.isIOS){
                history.pushState({ data: 'myPage' },null,null);
                history.pushState({ data: 'openMeun' },null,null);
            }
        },300);
    }
    function closeAppSet_direct(){
        $('#appSettings').hide();
        lgkorUI.setEnableAppScrollBottomMenu(true);
        lgkorUI.showAppBottomMenu(true);
        if(vcui.detect.isAndroid){
            lgkorUI.removeHistoryBack('openTotalmenu');
            setTimeout(function(){
                $('html').removeClass('scroll-fixed');
                $('body').removeClass('scroll-lock');
            },100);
        }else if(vcui.detect.isIOS){
            history.back();
        }
        isMyPage = false;
    }

    //BTOCSITE-54662 IOS앱모바일 전체메뉴열고 스크롤시 하단바 노출 비노출 작업
    //BTOCSITE-56439 APP > B2B 전체메뉴 오픈시 하단바 비노출
    /*
    - B2C 스크롤에 따라 하단바 노출, 비노출 작동 여부
        작업기능정의 : 페이지 O, 전체메뉴 O
        1 AOS APP   : 페이지 O, 전체메뉴 O
        2 IOS APP   : 페이지 O, 전체메뉴 X (setIOSToTalMenuBottomBar)
    
    - B2B 스크롤에 따라 하단바 노출, 비노출 작동 여부
        작업기능정의 : 페이지 O, 전체메뉴 X
        1 AOS APP   : 페이지 O, 전체메뉴 O (openB2BAppBar, closeB2BAppBar)
        2 IOS APP   : 페이지 O, 전체메뉴 X (openB2BAppBar)
    */
    function openB2BAppBar(){
        if(isB2B && isApp()){
            if( vcui.detect.isIOS ){
                lgkorUI.showAppBottomMenuOver(false);
                lgkorUI.showAppBottomMenu(false);
                setTimeout(function(){
                    lgkorUI.showAppBottomMenuOver(false);
                    lgkorUI.showAppBottomMenu(false);
                },20);
            }else if(vcui.detect.isAndroid){
                lgkorUI.showAppBottomMenu(false);
                lgkorUI.setEnableAppScrollBottomMenu(false);
            }
        }
    }
    function closeB2BAppBar(){
        if(isB2B && isApp()){
            if( vcui.detect.isIOS ){
            }else if(vcui.detect.isAndroid){
                lgkorUI.setEnableAppScrollBottomMenu(true);
            }
        }
    }
    function setIOSToTalMenuBottomBar(){
        if( isApp() ) {
            var $nav = $('.mobile-nav-button');
            if( vcui.detect.isIOS ){
                if(isB2B){
                    //console.log('IOS APP > B2B일때 전체메뉴열고 스크롤시 하단바 노출 비노출 이벤트 금지');
                    return;
                }
                if($(".KRP0008").length == 1 || $(".officehome").length == 1){
                    //console.log('PDP일때 전체메뉴열고 스크롤시 하단바 노출 비노출 이벤트 금지')
                    return;
                }
                var prevScrollTop = 0;
                var isShowBottom = 'null';
                var isPause = false;
                var topId = null;
                var bottomId = null;
                $('.header-bottom.header-new').off('scroll').on('scroll',function(){
                    //스크롤이 바운스 될때 예외처리
                    if(isPause == true){ return; }
                    if ($(this)[0].scrollTop < 0){
                        isPause = true;
                        clearTimeout(topId);
                        topId = setTimeout(function(){
                            prevScrollTop = $(this).scrollTop();
                            isPause = false;
                        },800);
                    } 
                    if ($(this)[0].scrollTop > $(this)[0].scrollHeight - $(this)[0].clientHeight) {
                        isPause = true;
                        clearTimeout(bottomId);
                        bottomId = setTimeout(function(){
                            prevScrollTop = $(this).scrollTop();
                            isPause = false;
                        },800);
                    }
                    
                    if($nav.hasClass('active')){
                        var deltaY = prevScrollTop - $(this).scrollTop();
                        if( deltaY < 0 ){
                            if(isShowBottom !== false){
                                //console.log('네이티브 하단바 비노출');
                                lgkorUI.showAppBottomMenuOver(false);
                            }
                            isShowBottom = false;
                        }else{
                            if(isShowBottom !== true){
                                //console.log('네이티브 하단바 노출');
                                lgkorUI.showAppBottomMenuOver(true);
                            }
                            isShowBottom = true;
                        }
                    }
                    prevScrollTop = $(this).scrollTop();
                });
            }
        }
    }
}